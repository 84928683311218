import React, { useEffect, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";
import people from "./images/people.webp";
import Navbar1 from "../Navbar";
import dtufoto from "./images/dtu.webp";
import blog2img from "./images/blog21.webp";
import blog3img from "./images/blog31.jpg";
import blog4img from "./images/blog4.webp";
import { useNavigate } from "react-router-dom";
import {
  ButtonContainer,
  NavButton,
  Separator,
  Dot,
  GlobalStyle,
  Container,
  Header,
  Title,
  Author,
  Date,
  HeaderImage,
  Content,
  Paragraph,
  ReadMoreButton,
  Footer,
  BlogCard,
  CardImage,
  CardContent,
  CardTitle,
  CardAuthor,
  CardDate,
  CardDescription,
  CardFooter,
} from "../styles";
import AnimatedButtons from "../Buttons/Animations";

import { blogarray } from "./blogarray";

const Blog2 = () => {
  const navigate = useNavigate();

  const topRef = useRef(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  const handleReadMore = (blogId) => {
    navigate(`/blog${blogId}`); // Example URL structure, adjust as per your routing setup
  };

  const scrollToTop = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div ref={topRef} style={{ height: "0", margin: "0", padding: "0" }}>
        <GlobalStyle />
        <Navbar1 />
        <Container>
          <Header>
            <HeaderImage src={blog2img} alt="Header Image" />
            <Title>Discovering DTU: A Guide for Freshers</Title>
            <Author>by Requesto</Author>
            <Date>July 18, 2024</Date>
          </Header>
          <Separator role="separator">
            <Dot />
            <Dot />
            <Dot />
          </Separator>

          <Content>
            <Paragraph>
              Welcome to Delhi Technological University (DTU), a place where
              dreams come to life. As a tier-1 engineering college, DTU attracts
              thousands of students who come with high expectations and
              enthusiasm. This guide is crafted to help freshers navigate the
              main attractions on our vibrant campus and make the most of their
              time here.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Exploring DTU: The Heart of Campus Life Connectivity and
              Surroundings DTU is strategically located in Delhi with excellent
              connectivity. For just ₹10, you can take an auto to the nearest
              metro station, giving you easy access to the entire city. With
              Rohini just a walk away, you have a plethora of dining options to
              indulge in some delicious food.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              The Open Air Theatre (OAT) The OAT is the centerpiece of DTU’s
              cultural scene. It’s where the magic happens—big events, society
              functions, and meetups all take place here. Whether it's a concert
              by a new artist or a major college event, the OAT is where you
              want to be. Adjacent to the OAT is the mini OAT, a smaller yet
              equally charming venue. It features a beautifully structured
              seating area and hosts auditions, society activities, and smaller
              fests.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              The New CS Building The new Computer Science (CS) building is a
              state-of-the-art facility with centralized air conditioning. It
              serves students from CS, IT, Software, and Mathematics and
              Computing disciplines. While it’s a modern marvel, it’s a reminder
              that not all departments enjoy the same luxuries.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              Raj Soin Canteen A favorite among students, the Raj Soin Canteen
              is famous for its affordable yet delicious offerings like chole
              bhature and the fire oreo shake, all for just ₹40. Despite the
              mosquito issue, the food makes it worthwhile. Nearby, there’s also
              a Domino's to satisfy your pizza cravings.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              Mik-Mak: The Night Canteen For hostel residents, Mik-Mak is the
              go-to spot at night. Open till late, it’s a haven for those
              midnight cravings—whether it's a plate of maggie at 2 AM or just a
              place to chill. The ambiance is a mix of peace and chaos, making
              it a unique experience. Girls can safely hang out here till 11 PM,
              joining the bustling night crowd.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              Raj Soin Hall Raj Soin Hall is not just a canteen access point but
              also a venue for large functions like orientations, hackathons,
              and competitions. It’s a cultural hub where events like India
              finals or IND vs PAK matches are screened, drawing large crowds
              for an exciting communal experience.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>
            <Paragraph>
              The Sports Complex DTU’s sports complex rivals the campuses of
              many other prestigious institutes. It boasts two basketball
              courts, three tennis courts, six to seven badminton courts, three
              table tennis tables, cricket nets, an open gym, and a closed gym.
              The gym operates from 6-10 AM and 4-8 PM. While the gym facilities
              are commendable, you might prefer an outside gym. However, the
              other sports facilities are top-notch and accessible anytime,
              ensuring you can always find a spot to play and have fun.
            </Paragraph>
            <Separator role="separator">
              <Dot />
              <Dot />
              <Dot />
            </Separator>

            <Paragraph>
              Embrace the DTU Experience DTU is more than just an academic
              institution; it’s a place where you’ll create lasting memories,
              forge lifelong friendships, and discover new passions. From the
              bustling OAT to the peaceful chaos of Mik-Mak, every corner of the
              campus has something special to offer. Welcome to DTU, and make
              the most of your journey here!
            </Paragraph>
          </Content>
          <br />
          <br />
          <br />
          <br />
          <ButtonContainer>
            <NavButton onClick={() => navigate("/blog")}>Previous</NavButton>
            <NavButton onClick={() => navigate("/blog3")}>Next</NavButton>
          </ButtonContainer>
          {blogarray.map((blog) => (
            <BlogCard key={blog.id}>
              <CardImage src={blog.image} alt="Blog Preview" />
              <CardContent>
                <CardTitle>{blog.title}</CardTitle>
                <CardAuthor>by {blog.author}</CardAuthor>
                <CardDate>{blog.date}</CardDate>
                <CardDescription>{blog.description}</CardDescription>
              </CardContent>
              <CardFooter>
                <ReadMoreButton onClick={() => handleReadMore(blog.id)}>
                  Read More
                </ReadMoreButton>
              </CardFooter>
            </BlogCard>
          ))}
        </Container>
        {/* <button className="scroll-to-top" onClick={scrollToTop}>
        Scroll to Top
      </button> */}
        <AnimatedButtons />
        <Footer>&copy; 2024 Requesto. All rights reserved.</Footer>
      </div>
    </>
  );
};

export default Blog2;
