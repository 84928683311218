import React, { useState } from 'react'
import './Cover.css'
import './CoverBox'
import CoverBox from './CoverBox';
import SignUp from './SignUp';
import SignIn from './SignIn';
import L from './L';




const MobileCover = (to) => {
  const [showModal, setShowModal] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);

  const toggleModal = (value) => {
    setShowModal(value);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const toggleSignInModal = (value) => {
    setShowSignInModal(value);
  };

  const closeSignInModal = () => {
    setShowSignInModal(false);
  };
  return (
    <div>
      <L toggleModal={toggleModal} toggleSignInModal={toggleSignInModal}/>
      <SignUp showModal={showModal} closeModal={closeModal} />
      <SignIn showSignInModal={showSignInModal} closeSignInModal={closeSignInModal} />
    </div>
  )
}

export default MobileCover
