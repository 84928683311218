import React, { useState } from "react";
import Chatbutton from "./Chatbutton";
import AcademicButton from "./AcademicButton";
import "./Animations.css"; // Import your animation-specific CSS
import ViewRequestButton from "./ViewRequestButton";
import YourRequestButton from "./YourRequestButton";
import AcceptRequestButton from "./AcceptedRequestButton";
import MicroLearnButton from "./MicrolearningButton";
import PostDeliveryButton from "./PostDeliveryButton";
import TalentBoardButton from "./TalentBoardButton";

function AnimatedButtons() {
  const [showButtons, setShowButtons] = useState(false);

  const toggleButtons = () => {
    setShowButtons((prevState) => !prevState);
  };

  return (
    <div className={`animated-buttons ${showButtons ? "show-buttons" : ""}`}>
      <button className="toggle-button" onClick={toggleButtons}>
        •••
      </button>
      {showButtons && (
        <>
          {/* <Chatbutton className="chatbutton" /> */}
          <PostDeliveryButton className="viewrequestbutton" />
          <AcademicButton className="academicbutton" />
          {/* <MicroLearnButton className="academicbutton" /> */}
          <ViewRequestButton className="viewrequestbutton" />
          {/* <YourRequestButton className="viewrequestbutton" /> */}
          {/* <AcceptRequestButton className="viewrequestbutton" /> */}
          {/* <TalentBoardButton className="viewrequestbutton" /> */}
        </>
      )}
    </div>
  );
}

export default AnimatedButtons;
