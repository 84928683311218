import React, { useEffect, useState } from 'react';
import './Blog5.css';
import './Blog.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faComments } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';

const Blog5 = () => {
    const [requests, setRequests] = useState([]);
    const [name, setName] = useState(localStorage.getItem('name')||'testacceptor');

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/requests`);
                const data = await response.json();
                setRequests(data);
            } catch (error) {
                console.error('Failed to fetch requests:', error);
            }
        };

        fetchRequests();
    }, []);

    const handleAccept = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/requests/accept/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ acceptor: name }),
            });

            if (response.ok) {
                const updatedRequest = await response.json();
                setRequests(requests.map(request => 
                    request._id === updatedRequest._id ? updatedRequest : request
                ));
            } else {
                console.error('Failed to accept the request');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleDelete = async (code) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/requests/delete?code=${encodeURIComponent(code)}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                setRequests(requests.filter(request => request.code !== code));
            } else {
                console.error('Failed to delete requests');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const navigate=useNavigate()

    const handleChat = (code) => {
        localStorage.setItem('generatedCode', code);
        navigate('/chatroom');
    };

    return (
        <div className="blog">
            <h1>Request Board</h1>
            <div className="request-board">
                {requests.map(request => (
                    <div key={request._id} className="request-card">
                        <div className="request-info">
                            <h3>{request.username}</h3>
                            <p>{request.code}</p>
                        </div>
                        <div className="request-actions">
                            {request.acceptor === "none" ? (
                                <>
                                    <button className="accept-btn" onClick={() => handleAccept(request._id)}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </button>
                                    
                                </>
                            ) : (
                                <>
                                    <button className="chat-btn" onClick={() => handleChat(request.code)}>
                                        <FontAwesomeIcon icon={faComments} /> Chat
                                    </button>
                                    <button 
                                        className="decline-btn" 
                                        onClick={() => handleDelete(request.code)}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                </>
                                
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Blog5;
