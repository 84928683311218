import React from "react";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import ReactGA from "react-ga4";

ReactGA.initialize("G-830YGE01HC");

const ContactUs = () => {
  const gaEventTracker = useAnalyticsEventTracker("Contact us");

  const handleCallClick = (event) => {
    event.preventDefault();
    gaEventTracker("call");
  };

  const handleEmailClick = (event) => {
    gaEventTracker("email");
  };

  return (
    <div>
      <h3>Contact Us</h3>
      <div>
        <a href="#" onClick={handleCallClick}>
          Call Us
        </a>
      </div>
      <div>
        <a href="mailto:someone@example.com" onClick={handleEmailClick}>
          Write to us
        </a>
      </div>
    </div>
  );
};

export default ContactUs;
