// blogs.js
import dtufoto from "./images/dtu.webp";
import blog2img from "./images/blog21.webp";
import blog3img from "./images/blog31.jpg";
import blog4img from "./images/blog4.webp";
import ninja from "./images/ninja.webp";

export const blogarray = [
  {
    id: "1",
    title: "Welcome to DTU: A Glimpse into Your Future",
    author: "Requesto",
    date: "July 18, 2024",
    image: dtufoto,
    description:
      "Understand DTU through its roots. Welcome to DTU, a Tier 1 engineering college...",
  },
  {
    id: "2",
    title: "Discovering DTU: A Guide for Freshers",
    author: "Requesto",
    date: "July 18, 2024",
    image: blog2img,
    description:
      "Welcome to Delhi Technological University (DTU), a place where dreams come to life.",
  },
  {
    id: "3",
    title: "Navigating the Hostel and PG Scene at DTU: A Freshers' Guide",
    author: "Requesto",
    date: "July 18, 2024",
    image: blog3img,
    description:
      "As you embark on this exciting journey, one of the first things you'll need to figure out is your accommodation.",
  },
  {
    id: "4",
    title: "Discovering Society Culture at DTU: A Guide for Freshers",
    author: "Requesto",
    date: "July 18, 2024",
    image: blog4img,
    description:
      "DTU, a prestigious tier-1 engineering institution that competes with the IITs in various fields, Spanning 164 acres.",
  },
  {
    id: 7,
    title: "DTU After Dark: A Guide to Nightlife on Campus",
    author: "Requesto",
    date: "August 12, 2024",
    description:
      "Discover the hidden side of DTU after the sun sets. From late-night study spots to midnight snacks, here's everything you need to know to make the most of your nights at DTU.",
    image: ninja, // Update with the actual path to your image
  },
  // Add more blog objects here as needed
];
