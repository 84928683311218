import React, { useState } from 'react';
import './ViewRequestButton.css';
import styled from 'styled-components';
import { FiEye } from 'react-icons/fi';
import AcademicRequestModal from '../RequestTemplates/AcademicRequestModal'; // Import the modal component
import { useNavigate } from 'react-router-dom';

const ViewRequestButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const handleClick = () => {
  //   setIsModalOpen(true); 
  // };

  // const handleCloseModal = () => {
  //   setIsModalOpen(false); 
  // };
  const navigate= useNavigate()
  const handleClick=()=>{
    navigate("/assignmentrequests")

  }

  const ViewRequestIcon = styled(FiEye)`
    width: 30px;
    height: 30px;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  `;

  return (
    <div>
      <div 
        className={`viewrequest-button`} 
        onClick={handleClick}
      >
        <div className="viewrequest-icon">
          <ViewRequestIcon />
        </div>
        <div className="viewrequest-text">
          <p>View Requests</p>
        </div>
      </div>
     
    </div>
  );
};

export default ViewRequestButton;
