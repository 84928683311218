import React, { useEffect, useState } from "react";
import "./PostDeliveryButton.css";
import styled from "styled-components";
import { FiMessageSquare, FiSend } from "react-icons/fi";

import DeliveryRequestModal from "../RequestTemplates/DeliveryRequestModal";

const PostDeliveryButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const ChatIcon = styled(FiSend)`
    width: 30px;
    height: 30px;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  `;

  return (
    <div>
      <div className={`chat-button`} onClick={handleClick}>
        <div className="chat-icon">
          <ChatIcon />
        </div>
        <div className="chat-text">
          <p>Request Delivery</p>
        </div>
      </div>
      <DeliveryRequestModal
        showModal={isModalOpen}
        closeModal={handleCloseModal}
      />
    </div>
  );
};

export default PostDeliveryButton;
