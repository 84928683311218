import React from "react";
import "./Subscription.css";

const Subscription = () => {
  return (
    <div className="subscription-container">
      <h1 className="subscription-h1">Choose the Right Plan for You</h1>
      <div className="subscription-plans">
        <div className="subscription-plan">
          <h2 className="subscription-h2">Requesto</h2>
          <p className="subscription-price">Free</p>
          <p className="subscription-description">For individual users</p>
          <ul className="subscription-features">
            <li>Post 1 request at a time</li>
            <li>Apply to 1 request at a time</li>
            <li>Basic community support</li>
          </ul>
          <button className="subscription-subscribe-btn">Get Started</button>
        </div>

        <div className="subscription-plan highlighted">
          <h2 className="subscription-h2">Requesto+</h2>
          <p className="subscription-price">Rs 29/month</p>
          <p className="subscription-description">For small teams</p>
          <ul className="subscription-features">
            <li>Post multiple requests simultaneously</li>
            <li>Apply to multiple requests at once</li>
            <li>Priority support</li>
          </ul>
          <button className="subscription-subscribe-btn">Most Popular</button>
        </div>

        <div className="subscription-plan">
          <h2 className="subscription-h2">RequestoX</h2>
          <p className="subscription-price">Rs 99/month</p>
          <p className="subscription-description">For large organizations</p>
          <ul className="subscription-features">
            <li>Post on different servers</li>
            <li>All features in Requesto+</li>
            <li>Dedicated WhatsApp notifications</li>
          </ul>
          <button className="subscription-subscribe-btn">Subscribe Now</button>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
