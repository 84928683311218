import videoSrc from "./requesto_desktop.mp4";
import React from "react";
import "./Video.css";

// Adjust the filename as needed

const Vide = () => {
  return (
    <div className="video-container">
      <video className="full-screen-video" autoPlay loop muted>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Vide;
