import React, { useState } from "react";
import "./MicrolearnRequestModal.css"; // Import the CSS file for styling

const MicroLearnRequestModal = ({ showModal, closeModal }) => {
  const [title, setTitle] = useState("");
  const [learningObjective, setLearningObjective] = useState(""); // Commented out in the form
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("Tech"); // Default category
  const [days, setDays] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [details, setDetails] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const deadline = `${days} days ${hours} hours ${minutes} minutes`;
      const sender = localStorage.getItem("name"); // Assuming the sender's name is stored in localStorage
      const sender_email = localStorage.getItem("email");

      // Reusing the academic request API as a stopgap
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/academic-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            assignment: title, // Use assignment field for title
            price, // Price remains the same
            subject: category, // Use subject field for category
            deadline, // Deadline remains the same
            details, // Details remain the same
            sender,
            sender_email,
          }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        // alert("Microlearning request submitted successfully");
        setTitle("");
        setPrice("");
        setCategory("Tech"); // Reset to default category
        setDays("");
        setHours("");
        setMinutes("");
        setDetails("");
        closeModal();
        setTimeout(() => {
          setShowNotification(true);
          setTimeout(() => {
            setShowNotification(false);
          }, 2500); // Hide notification after 2.5 minutes
        }, 300);
      } else {
        alert(result.error || "An error occurred");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred");
    }
  };

  return (
    <>
      <div className={`modal-overlay ${showModal ? "show" : ""}`}>
        <div className="modal-content">
          <h3 className="mlr-2">Post Microlearning Request</h3>
          <span className="close-button-mlr" onClick={closeModal}>
            &times;
          </span>
          <form onSubmit={handleSubmit} className="mlr-6">
            <label className="mlr-10">
              Topic/Request Title:
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                className="mlr-11"
              />
            </label>
            <label className="mlr-10">
              Category:
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
                className="mlr-11"
              >
                <option value="Tech">Tech</option>
                <option value="Business">Business</option>
                <option value="Design">Design</option>
                <option value="Health">Health</option>
                <option value="Other">Other</option>
                {/* Add more categories as needed */}
              </select>
            </label>
            <label className="mlr-10">
              Price (₹):
              <input
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
                className="mlr-11"
              />
            </label>
            <label className="mlr-10">
              Deadline:
              <div className="deadline-container">
                <input
                  type="number"
                  value={days}
                  onChange={(e) => setDays(e.target.value)}
                  required
                  className="deadline-box"
                  placeholder="Days"
                />
                <input
                  type="number"
                  value={hours}
                  onChange={(e) => setHours(e.target.value)}
                  required
                  className="deadline-box"
                  placeholder="Hours"
                />
                <input
                  type="number"
                  value={minutes}
                  onChange={(e) => setMinutes(e.target.value)}
                  required
                  className="deadline-box"
                  placeholder="Minutes"
                />
              </div>
            </label>
            <label className="mlr-10">
              Details (Optional):
              <textarea
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                className="mlr-14"
              />
            </label>
            <button type="submit" className="mlr-22">
              Submit Request
            </button>
          </form>
        </div>
      </div>
      {showNotification && (
        <ul className="notification-list">
          <li
            aria-live="polite"
            aria-atomic="true"
            role="status"
            tabIndex="0"
            className="style-0"
          >
            <div data-icon="" className="style-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="style-2"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                  className="style-3"
                />
              </svg>
            </div>
            <div data-content="" className="style-4">
              <div data-title="" className="style-5">
                Request sent successfully!
              </div>
            </div>
          </li>
        </ul>
      )}
    </>
  );
};

export default MicroLearnRequestModal;
