import React from 'react';
import './Navbar.css';
import profileimg from './images/profile.png'; // Import the profile image
import logo from './images/r1.jpg'; // Import the logo image
import { Link } from 'react-router-dom';


const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navbar-title">
        <Link to='/homepage'>
        <img src={logo} alt="Requesto Logo" className="navbar-logo" />
        </Link>
        
      </div>
      <div className="navbar-icon">
        {/* <img src={profileimg} alt="Profile" style={{height:45, width:45}}/> */}
      </div>
    </div>
  );
};

export default Navbar;
