import React, { useState, useEffect, useRef } from "react";
import "./Chatroom.css";
import { FiSend, FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";

const SOCKET_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}`;

const Chatroom = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [userName, setUserName] = useState(localStorage.getItem("name") || "a");
  const [roomName, setRoomName] = useState(
    localStorage.getItem("generatedCode")
  );
  const [RequestID, setRequestID] = useState(
    localStorage.getItem("generatedCode") || null
  );
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const socketRef = useRef(null);

  const formatDate = (date) => {
    const options = { hour: "2-digit", minute: "2-digit" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  useEffect(() => {
    const storedRequestID = localStorage.getItem("generatedCode");
    if (storedRequestID) {
      setRequestID(storedRequestID);
    }

    socketRef.current = io(SOCKET_SERVER_URL, {
      withCredentials: true,
    });

    socketRef.current.on("connect", () => {
      console.log("Connected to the server");
      // Join the room
      socketRef.current.emit("joinRoom", { roomName, userName });
    });

    socketRef.current.on("receiveMessage", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    socketRef.current.on("disconnect", () => {
      console.log("Disconnected from the server");
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [roomName, userName]);

  const sendMessage = async () => {
    if (input.trim() !== "") {
      const newMessage = {
        text: input,
        time: new Date(),
        username: userName,
        roomname: roomName,
        RequestID: RequestID,
      };

      try {
        socketRef.current.emit("sendMessage", {
          roomName,
          message: newMessage,
        });

        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/messages/sendMessage`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newMessage),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to send message");
        }

        // setMessages([...messages, newMessage]);
        setInput("");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchMessages = async (RequestID) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/messages/getMessages?RequestID=${RequestID}`
      );
      if (response.ok) {
        const data = await response.json();
        const messagesWithDate = data.messages.map((msg) => ({
          ...msg,
          time: new Date(msg.time),
        }));
        setMessages(messagesWithDate);
      } else {
        throw new Error("Failed to fetch messages");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const handleArrowPress = () => {
    navigate("/homepage");
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    fetchMessages(RequestID);
  }, []);

  return (
    <div className="chat-container">
      <div className="chat-header">
        <FiArrowLeft className="icon back-icon" onClick={handleArrowPress} />
        Requesto
      </div>
      <div className="chat-messages" id="chat-messages">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${
              message.username === userName ? "sent" : "received"
            }`}
          >
            <div className="message-content">
              <div className="message-text">{message.text}</div>
              <div className="message-time">
                {formatDate(new Date(message.time))}
              </div>
            </div>
          </div>
        ))}

        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Message..."
        />
        <FiSend className="icon send-icon" onClick={sendMessage} />
      </div>
    </div>
  );
};

export default Chatroom;
