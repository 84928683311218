import React from "react";
import "./L.css"; // Import the CSS file
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode"; // To decode the token
import dragon from "./images/dragon.png";
import people from "./images/people.webp";

const L = ({ toggleModal, toggleSignInModal }) => {
  const navigate = useNavigate();
  const handleGoogleSuccess = (response) => {
    const userObject = jwtDecode(response.credential);
    console.log(userObject);
    // Send this token to the backend for verification and user creation
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/auth/google`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: response.credential,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.token) {
          localStorage.setItem("showSignInNotification", "true");
          // Store the JWT token in localStorage or context
          localStorage.setItem("token", data.token);
          const decodedToken = jwtDecode(data.token);
          localStorage.setItem("name", decodedToken.user.name);
          localStorage.setItem("email", decodedToken.user.email);

          // alert("Sign in successful");
          navigate("/homepage");
        } else {
          console.error("Error signing in with Google");
        }
      });
  };
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="mobile-upper-half">
        <img src={people} />
        {/* Upper half with background color */}
        {/* You can add content or leave it empty */}
      </div>
      <div className="mobile-lower-half">
        {/* Lower half with white background */}
        <strong>Welcome to Requesto!</strong>
        <p>Post requests / Earn money with your talent</p>
        <Link onClick={() => toggleSignInModal(true)} className="button-signin">
          SIGN IN TO REQUESTO
        </Link>
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={() => {
            console.log("Login Failed");
          }}
        />
        <p>
          Don't have an account?{" "}
          <Link onClick={() => toggleModal(true)}>Sign up</Link>
        </p>
        <p>
          © 2024 Requesto. All rights reserved.{" "}
          <Link to="/tc">Terms and Conditions</Link>.
        </p>
      </div>
    </div>
  );
};

export default L;
