import React, { useState, useRef, useEffect } from "react";
import "./Homepage.css";
import AcademicButton from "./Buttons/AcademicButton";
import AnimatedButtons from "./Buttons/Animations";
import Chatbutton from "./Buttons/Chatbutton";
import MinimalistMobileMenu from "./MinimalistMobileMenu";
import Navbar1 from "./Navbar";
import Navbar from "./Navbar";
import WelcomePopups from "./WelcomePopups";
import { blogarray } from "./blogs/blogarray";
import blog4img from "./images/blog4.webp";
import blog2img from "./images/blog21.webp";
// import blog2img from './images/blog21.webp'
import blog3img from "./images/blog31.jpg";
import dtufoto from "./images/dtu.webp";
import or1 from "./orientation_1.jpg";
import or2 from "./orientation_2.jpg";
import or3 from "./orientation_3.jpg";
import {
  ButtonContainer,
  NavButton,
  Separator,
  Dot,
  GlobalStyle,
  Container,
  Header,
  Title,
  Author,
  Date,
  HeaderImage,
  Content,
  Paragraph,
  ReadMoreButton,
  Footer,
  BlogCard,
  CardImage,
  CardContent,
  CardTitle,
  CardAuthor,
  CardDate,
  CardDescription,
  CardFooter,
} from "./styles";
import ws from "./whatsapp.png";
import { jwtDecode } from "jwt-decode";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";

function Homepage() {
  const blogRef = useRef(null);
  const [clickCount, setClickCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState("");
  const [showEmailVerification, setShowEmailVerification] = useState(true);
  const [showCustomAlert, setShowCustomAlert] = useState(true);
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [email, setEmail] = useState("astrixdtu@gmail.com");
  const [isVerified, setIsVerified] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showWelcomePopups, setShowWelcomePopups] = useState(false);

  const learnMore = () => {
    if (blogRef.current) {
      blogRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    // Check if there's a sign-in notification flag
    const signInNotificationFlag = localStorage.getItem(
      "showSignInNotification"
    );
    if (signInNotificationFlag === "true") {
      setShowNotification(true);
      setShowWelcomePopups(true);
      localStorage.removeItem("showSignInNotification"); // Clear the flag
      setTimeout(() => {
        setShowNotification(false);
      }, 2500); // Hide notification after 2.5 seconds
    }
    const preloadImage = (url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => setImageLoaded(true);
    };

    preloadImage(blog2img);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setEmail(decodedToken.user.email);
      setIsVerified(decodedToken.user.isVerified);
    }
  }, []);
  useEffect(() => {
    const trackPageView = () => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    };

    trackPageView(); // Track initial load
    window.addEventListener("popstate", trackPageView); // Track on route changes

    return () => {
      window.removeEventListener("popstate", trackPageView);
    };
  }, []);

  const handleHeroClick = () => {
    setClickCount((prevCount) => {
      if (prevCount + 1 === 6) {
        setShowModal(true);
        return 0;
      }
      return prevCount + 1;
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    if (password === "123456") {
      navigate("/blog5");
      setShowModal(false);
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  const handleDismissEmailVerification = () => {
    setShowEmailVerification(false);
  };

  const handleDismissCustomAlert = () => {
    setShowCustomAlert(false);
  };

  const handleReadMore = (blogId) => {
    navigate(`/blog${blogId}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    }); // Example URL structure, adjust as per your routing setup
  };

  const handleSendOTP = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/auth/send-otp`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setOtpSent(true);
        setShowOtpPopup(true);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/auth/verify-otp`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, otp }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        // Store the new token in local storage
        localStorage.setItem("token", data.token);

        // Update the state to reflect verification status
        setIsVerified(true);

        // Close the OTP popup
        setShowOtpPopup(false);

        // Optionally dismiss the custom alert
        setShowCustomAlert(false);
      } else {
        // Handle verification failure (optional)
        console.error("OTP verification failed:", data.message);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  return (
    <div className="a1Blog">
      {showWelcomePopups && <WelcomePopups />}

      <Navbar1 />

      {showCustomAlert && !isVerified && (
        <div className="a1custom-alert">
          You should verify your email now.
          <button
            type="button"
            className="a1verify-button"
            onClick={handleSendOTP}
          >
            Verify Now!
          </button>
          <button
            type="button"
            className="a1close-button"
            aria-label="Close"
            onClick={handleDismissCustomAlert}
          >
            &times;
          </button>
        </div>
      )}

      <section
        className="a1hero"
        id="home"
        onClick={handleHeroClick}
        style={{ backgroundImage: `url(${blog2img})` }}
      >
        <div className="a1hero-content">
          <h1>Welcome to Requesto</h1>
          <p>Explore Everything You Need to Know About DTU</p>
          <button className="a1cta" onClick={learnMore}>
            Learn More
          </button>
        </div>
      </section>

      <section className="a1about" id="about">
        <div className="a1container">
          <h2>About DTU</h2>
          <p>
            Delhi Technological University (DTU) is a premier institution in
            India, renowned for its cutting-edge research and excellent
            education.
          </p>
          <p>
            Experience a legacy of innovation, exceptional faculty, and a
            vibrant student community.
          </p>
        </div>
      </section>
      <div className="a1fresher-alert">
        <strong>Attention Freshers: Society Recruitment Update</strong>
        <ul>
          <li>
            <strong>Settle In First</strong>: Focus on getting settled into your
            classes. After a few days, you’ll start noticing posters and
            advertisements for various societies around the campus.
          </li>
          <li>
            <strong>Recruitment Forms</strong>: Most societies will share their
            recruitment forms in the WhatsApp groups you’ve joined, including
            your class group. If you haven’t joined these groups yet, be sure to
            do so, as they provide important updates, including society
            information.
          </li>
          <li>
            <strong>Auditions and Selections</strong>: Each society conducts
            auditions and requires you to fill out recruitment forms. Entry is
            based on selection—you cannot join directly.
          </li>
          <li>
            <strong>Our Advice</strong>: Attend every audition that interests
            you and give it your best shot!
          </li>
        </ul>
      </div>
      <section className="a1orientation" id="orientation">
        <div className="a1container">
          <h2>Attractions of DTU</h2>
          <div className="a1orientation-gallery">
            {/* <img src={or1} alt="Orientation 1" className="a1orientation-image" /> */}
            {/* <img src={or2} alt="Orientation 2" className="a1orientation-image" /> */}
            <img
              src={or3}
              alt="Orientation 3"
              className="a1orientation-image"
            />
          </div>
        </div>
      </section>
      <section className="a1blog" id="blog" ref={blogRef}>
        <div className="a1container">
          <h2>Latest Blog Posts</h2>
          {blogarray.map((blog) => (
            <BlogCard key={blog.id}>
              <CardImage src={blog.image} alt="Blog Preview" />
              <CardContent>
                <CardTitle>{blog.title}</CardTitle>
                <CardAuthor>by {blog.author}</CardAuthor>
                <CardDate>{blog.date}</CardDate>
                <CardDescription>{blog.description}</CardDescription>
              </CardContent>
              <CardFooter>
                <ReadMoreButton onClick={() => handleReadMore(blog.id)}>
                  Read More
                </ReadMoreButton>
              </CardFooter>
            </BlogCard>
          ))}
        </div>
      </section>

      <section className="a1whatsapp" id="whatsapp">
        <div className="a1container">
          <h2>Connect with Us on WhatsApp</h2>
          <p>Scan the QR code below to join our exclusive whatsapp group.</p>
          <img src={ws} alt="WhatsApp QR Code" className="a1whatsapp-qr" />
        </div>
      </section>

      <section className="a1connect" id="connect">
        <div className="a1container">
          <h2>Get in Touch</h2>
          <p>
            Want to connect with a senior or get more information about DTU?
            Reach out to us!
          </p>
          {/* <button className="a1connect-btn">Connect Now</button> */}
        </div>
      </section>

      {/* <AnimatedButtons /> */}
      <MinimalistMobileMenu />
      <footer className="a1footer">
        <p>&copy; 2024 Requesto. All rights reserved.</p>
      </footer>

      {showModal && (
        <div className="a1modal">
          <div className="a1modal-content">
            <span className="a1close" onClick={handleCloseModal}>
              &times;
            </span>
            <h2>Enter Password</h2>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              className="a1password-input"
            />
            <button
              onClick={handlePasswordSubmit}
              className="a1submit-password"
            >
              Submit
            </button>
          </div>
        </div>
      )}
      {showOtpPopup && (
        <div className="a1modal">
          <div className="a1modal-content">
            <span className="a1close" onClick={() => setShowOtpPopup(false)}>
              &times;
            </span>
            <h2>Enter OTP</h2>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="a1otp-input"
              placeholder="Enter OTP"
            />
            <button onClick={handleVerifyOTP} className="a1verify-otp">
              Verify OTP
            </button>
          </div>
        </div>
      )}
      {showNotification && (
        <ul className="notification-list">
          <li
            aria-live="polite"
            aria-atomic="true"
            role="status"
            tabIndex="0"
            className="style-0"
          >
            <div data-icon="" className="style-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="style-2"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                  className="style-3"
                />
              </svg>
            </div>
            <div data-content="" className="style-4">
              <div data-title="" className="style-5">
                Signed in Successfully
              </div>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
}

export default Homepage;
