import React, { useState } from "react";
import "./AcademicRequestModal.css";

// Import the CSS file for styling

const AcademicRequestModal = ({ showModal, closeModal }) => {
  const [assignment, setAssignment] = useState("");
  const [price, setPrice] = useState("");
  const [subject, setSubject] = useState(""); // Default subject
  const [days, setDays] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [details, setDetails] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const deadline = `${days} days ${hours} hours ${minutes} minutes`;

      const sender = localStorage.getItem("name"); // Assuming the sender's name is stored in localStorage
      const sender_email = localStorage.getItem("email"); // Assuming the sender's name is stored in localStorage
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/academic-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            assignment,
            price,
            subject,
            deadline,
            details,
            sender,
            sender_email,
          }),
        }
      );
      const result = await response.json();
      if (response.ok) {
        // Reset the form fields
        setAssignment("");
        setPrice("");
        setSubject("PH101");
        setDays("");
        setHours("");
        setMinutes("");
        setDetails("");
        closeModal();

        // Show "Request Redacted" notification
        setTimeout(() => {
          setShowNotification(true);
          setTimeout(() => {
            setShowNotification(false);
          }, 2500); // Hide notification after 2.5 minutes
        }, 300); // Small delay to ensure the modal closes before showing the notification
      } else {
        if (response.status === 403) {
          alert(result.message || "An error has occured");
        } else {
          alert(result.error || "An error occurred");
        }
      }
    } catch (error) {
      alert("An error occurred. Please try again.");
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className={`modal-overlay ${showModal ? "show" : ""}`}>
        <div className="modal-content">
          <h3 className="acr-2">Post Academic Request</h3>
          <span className="close-button-acr" onClick={closeModal}>
            &times;
          </span>
          <form onSubmit={handleSubmit} className="acr-6">
            <label className="acr-10">
              Assignment:
              <input
                type="text"
                value={assignment}
                onChange={(e) => setAssignment(e.target.value)}
                required
                className="acr-11"
              />
            </label>
            <label className="acr-10">
              Subject Code:
              <input
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
                className="acr-11"
              ></input>
            </label>
            {/* <label className="acr-10">
              Subject Code:
              <select
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
                className="acr-11"
              >
                <option value="PH101">PH101 - Physics</option>
                <option value="CS102">CS102 - Computer Science</option>
                
              </select>
            </label> */}
            <label className="acr-10">
              Price (₹):
              <input
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
                className="acr-11"
              />
            </label>
            <label className="acr-10">
              Deadline:
              <div className="deadline-container">
                <input
                  type="number"
                  value={days}
                  onChange={(e) => setDays(e.target.value)}
                  required
                  className="deadline-box"
                  placeholder="Days"
                />
                <input
                  type="number"
                  value={hours}
                  onChange={(e) => setHours(e.target.value)}
                  required
                  className="deadline-box"
                  placeholder="Hours"
                />
                <input
                  type="number"
                  value={minutes}
                  onChange={(e) => setMinutes(e.target.value)}
                  required
                  className="deadline-box"
                  placeholder="Minutes"
                />
              </div>
            </label>
            <label className="acr-10">
              Details (Optional):
              <textarea
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                className="acr-14"
              />
            </label>
            <button type="submit" className="acr-22">
              Submit Request
            </button>
          </form>
        </div>
      </div>
      {showNotification && (
        <ul className="notification-list">
          <li
            aria-live="polite"
            aria-atomic="true"
            role="status"
            tabIndex="0"
            className="style-0"
          >
            <div data-icon="" className="style-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="style-2"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                  className="style-3"
                />
              </svg>
            </div>
            <div data-content="" className="style-4">
              <div data-title="" className="style-5">
                Request sent successfully!
              </div>
              {/* {errorMessage && (
                <div className="popup-error">
                  <p>{errorMessage}</p>
                  <button onClick={() => setErrorMessage("")}>Close</button>
                </div>
              )} */}
            </div>
          </li>
        </ul>
      )}
    </>
  );
};

export default AcademicRequestModal;
