import React, { useState } from 'react';
import "./F.css";
import { Link } from 'react-router-dom';


const SignUp = ({ showModal, closeModal }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate passwords match
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          password: formData.password,
        }),
      });
      
      const result = await response.json();
      if (response.ok) {
        alert('User registered successfully');
        setFormData({ name: '', email: '', password: '', confirmPassword: '' }); // Reset form
        closeModal();
      } else {
        alert(result.error || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred');
    }
  };

  const isFormValid = () => {
    const { name, email, password, confirmPassword } = formData;
    return name && email && password && confirmPassword && (password === confirmPassword);
  };

  return (
    <>
      <div className={`modal-overlay ${showModal ? 'show' : ''}`}>
        <div className="modal-content">
          <div className="new-sign-up-1">
            <div className="new-sign-up-2">
              <a href='/' className="new-sign-up-3">
                <i className="new-sign-up-4"></i>
              </a>
              <h3 className="new-sign-up-5">Sign up with Email</h3>
              <span className="close-button" onClick={closeModal}>&times;</span>
            </div>
            <form name="register" className="new-sign-up-6" onSubmit={handleSubmit}>
              <div className="new-sign-up-7">
                <fieldset className="new-sign-up-8">
                  <legend className="new-sign-up-9">Sign up with Email</legend>
                  <div className="sub-error"></div>
                  <p className="new-sign-up-10">
                    <input type="text" name="name" required className="new-sign-up-11" value={formData.name} onChange={handleChange} />
                    <label className="new-sign-up-12">Name</label>
                    <span className="new-sign-up-13"></span>
                  </p>
                  <p className="new-sign-up-10">
                    <input type="text" name="email" required className="new-sign-up-11" value={formData.email} onChange={handleChange} />
                    <label className="new-sign-up-12">Email address</label>
                    <span className="new-sign-up-13"></span>
                  </p>
                  <p className="new-sign-up-10">
                    <input type="password" required name="password" className="new-sign-up-14" value={formData.password} onChange={handleChange} />
                    {/* <span className="new-sign-up-15"> */}
                      {/* <i className="new-sign-up-4"></i>Must be 6-18 characters long.</span> */}
                    <label className="new-sign-up-16">Password</label>
                    <label className="_fly">
                      <input type="checkbox" className="new-sign-up-17" />
                      {/* <i className="new-sign-up-18">SHOW</i>
                      <i className="new-sign-up-19">HIDE</i> */}
                    </label>
                    <span className="new-sign-up-20"></span>
                  </p>
                  <p className="new-sign-up-10">
                    <input type="password" required name="confirmPassword" className="new-sign-up-14" value={formData.confirmPassword} onChange={handleChange} />
                    <label className="new-sign-up-16">Confirm Password</label>
                    <span className="new-sign-up-20"></span>
                  </p>
                  <div className="new-sign-up-21"></div>
                  <button type="submit" className="new-sign-up-22" disabled={!isFormValid()}>Sign Up</button>
                  {/* <p className="new-sign-up-23">Already have an account? 
                    <a href="/" title="Sign in" className="new-sign-up-24">Sign in</a>
                  </p> */}
                  <br/>
                </fieldset>
              </div>
            </form>
          </div>
          <span className="new-sign-up-25">
            <span className="new-sign-up-26">© 2024</span>
            <span className="new-sign-up-26"> Requesto all rights reserved, </span>
            <br/>
            <Link to="/privacy" title="terms of service" className="new-sign-up-27">
              Privacy Policy
            </Link>
          </span>
        </div>
      </div>
    </>
  );
};

export default SignUp;
