import React, { useState } from 'react';
import './AcademicButton.css';
import styled from 'styled-components';
import { FiBook } from 'react-icons/fi';
import AcademicRequestModal from '../RequestTemplates/AcademicRequestModal'; // Import the modal component

const AcademicButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const AcademicIcon = styled(FiBook)`
    width: 30px;
    height: 30px;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  `;

  return (
    <div>
      <div 
        className={`academic-button`} 
        onClick={handleClick}
      >
        <div className="academic-icon">
          <AcademicIcon />
        </div>
        <div className="academic-text">
          <p>Post Assignment</p>
        </div>
      </div>
      <AcademicRequestModal showModal={isModalOpen} closeModal={handleCloseModal} />
    </div>
  );
};

export default AcademicButton;
